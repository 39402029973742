<template>
  <div>
    <Frame title="Certificados" :titleAction="titleAction">
      <Title
        title="Datos generales"
        description="Indica el RFC titular del sello y la información correspondiente al certificado."
      />

      <Layout>
        <Column size="4">
          <Field label="RFC">
            <ComboBox
              :required="true"
              :disabled="readOnly"
              name="RFC"
              modelEndpoint="stamp/org/companies"
              api="stamp"
              data-text-field="RFC"
              data-value-field="RFC"
              :limitSearch="null"
              v-model="model.RFC"
              @select="onSelect"
              ref="companies"
              rules="required"
            />
          </Field>
        </Column>
        <Column size="4">
          <Field label="Descripción">
            <Input
              name="Descripción"
              :required="true"
              :readonly="readOnly"
              v-model="model.description"
              rules="required"
            />
          </Field>
        </Column>
        <Column>
          <Field label="Archivos (.cer y .key)">
            <Upload
              :required="true"
              :multiple="true"
              :fileExtensions="['cer', 'cert', 'key']"
              :showImage="files.length === 0 && !readOnly"
              v-model="inputModel"
              @input="onChangeFiles"
              name="Archivos (.cer y .key)"
              rules="required"
              :disabled="!$can('EditCertificate', 'STM') || readOnly"
            >
              <template>
                <div class="certfiles">
                  <CertificateFile v-for="(file, index) in files" :key="index" :file="file" />
                  <CertificateFile v-if="files.length === 1" :notFile="true" :fileExt="extFile" />
                </div>
              </template>
            </Upload>
          </Field>
        </Column>
        <Column size="4">
          <Field label="Contraseña">
            <Input
              :required="true"
              :readonly="readOnly"
              type="password"
              v-model="model.llavePassword"
              name="Archivos (.cer y .key)"
              rules="required"
            />
          </Field>
        </Column>
        <Column>
          <Field>
            <CheckBox
              :readonly="readOnly"
              label="Acepto los términos y condiciones"
              v-model="terms"
              rules="required"
              name="Términos y condiciones"
            />
          </Field>
        </Column>
      </Layout>
      <hr />
      <Layout>
        <Column align="right">
          <Button
            color="terciary-outlined"
            @click="readonly && !readOnly ? onCancelEdit() : $parent.close()"
          >
            {{ readOnly ? 'Salir' : 'Cancelar' }}
          </Button>
          <Button
            color="secondary"
            :disabled="!terms && !readOnly"
            @click="readOnly ? onEdit() : onSave()"
            v-if="$can('EditCertificate', 'STM')"
          >
            {{ readOnly ? 'Editar' : certificateToEdit ? 'Actualizar' : 'Guardar' }}
          </Button>
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
import CertificateFile from './CertificateFile.vue';

export default {
  name: 'CertificateForm',
  components: {
    CertificateFile,
  },
  props: {
    certificateToEdit: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleAction: 'Agregar',
      files: [],
      inputModel: [],
      extFile: '',
      fileTypes: [],
      terms: true,
      model: {
        llavePassword: '',
        companyCode: '',
        description: '',
        RFC: null,
      },
      readOnly: this.readonly,
    };
  },
  mounted() {
    if (this.readonly) this.$root.$emit('changeModeFrame', 'view');

    if (this.certificateToEdit) {
      this.titleAction = this.readonly ? 'Ver' : 'Editar';
      this.model = { ...this.certificateToEdit };
    }
  },
  methods: {
    onSelect(item) {
      if (item) {
        this.model.companyCode = item.item.CompanyCode;
      }
    },
    onChangeFiles(files) {
      if (files.length === 0) {
        return;
      }
      if (files.length > 2) {
        this.setNotification('Solo se puede subir 2 archivos (.cer y .key)', 'warning');
        this.inputModel = [];
        return;
      }

      files.forEach((x) => {
        if (!['key', 'cer', 'cert'].includes(this.getFiletype(x))) {
          return;
        }

        const file = this.files.find(
          (y) => y.name === x.name || this.getFiletype(y) === this.getFiletype(x)
        );
        if (file) {
          this.files.forEach((y, index) => {
            if (y.name === file.name || this.getFiletype(y) === this.getFiletype(file)) {
              this.files[index] = x;
            }
          });
        } else {
          this.files.push(x);
        }
      });
    },
    getFiletype(file) {
      const filename = file.name;
      return filename.split('.').pop();
    },
    async onSave() {
      const companies = this.$refs.companies.options;
      const companySelected = companies.find((x) => x.id === this.model.RFC);
      if (companySelected) {
        this.model.companyCode = companySelected.item.CompanyCode;
      }
      const validate = await this.isValid();

      if (!validate) {
        return;
      }

      if (this.files.length < 2) {
        this.setNotification('Hace falta subir los archivos (.cer y .key)', 'warning');
        return;
      }
      const formData = new FormData();
      Object.keys(this.model).forEach((x) => {
        formData.append(x, this.model[x]);
      });

      this.files.forEach((x) => {
        formData.append('files', x);
      });
      this.upload(
        !this.model.certificateID ? 'CSD/Generate' : 'CSD/Update',
        formData,
        'stamp',
        this.onUpload
      )
        .then(() => {
          this.$root.$emit('reloadData');
          this.setNotification('Certificado guardado correctamente', 'success');
          this.$parent.close();
        })
        .catch(({ response }) => {
          const { data } = response;
          this.setNotification(data, 'danger');
        });
    },
    onUpload() {},
    onEdit() {
      this.readOnly = false;
      this.titleAction = 'Editar';

      this.$root.$emit('changeModeFrame', 'edit');
    },
    onCancelEdit() {
      this.readOnly = true;
      this.titleAction = 'Ver';

      requestAnimationFrame(() => {
        this.$root.$children[0].$refs.validation.reset();
      });
      this.model = JSON.parse(JSON.stringify(this.certificateToEdit));
      this.terms = true;
      this.$root.$emit('changeModeFrame', 'view');
    },
  },
};
</script>

<style lang="scss">
.certfiles {
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  width: 100%;
  .certfile {
    width: 50%;
    padding: 8px;
    justify-content: center;
    box-sizing: border-box;
    .certfile-filename {
      max-width: 250px;
    }
  }
}
</style>
