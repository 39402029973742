<template>
  <div class="certfile">
    <div class="certfile-icon" :class="`certfile-icon--${notFile ? 'information' : 'success'}`">
      <i class="mdi mdi-check-circle" v-if="!notFile"></i>
      <i class="mdi mdi-information" v-if="notFile"></i>
    </div>
    <div class="certfile-text">
      <p v-if="notFile">Archivo {{ fileExt }} sin subir</p>
      <p class="certfile-filename" :title="file.name" v-if="!notFile">
        {{ file.name }}
      </p>
      <p class="certfile-detail" v-if="!notFile">Tamaño del archivo: {{ getSize() }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CertificateFile',
  props: {
    file: File,
    notFile: {
      type: Boolean,
      default: false,
    },
    fileExt: String,
  },
  methods: {
    getSize() {
      let { size } = this.file;
      const fileSizeExtensions = ['Bytes', 'KB', 'MB', 'GB'];
      let i = 0;
      while (size > 900) {
        size /= 1024;
        i += 1;
      }
      const exactSize = `${Math.round(size * 100) / 100} ${fileSizeExtensions[i]}`;
      return exactSize;
    },
  },
};
</script>

<style lang="scss">
.certfile {
  display: flex;
}
.certfile-icon {
  margin-right: 14px;

  font-size: 18px;
  &--success {
    color: #19bf95;
  }
  &--information {
    color: #5186ff;
  }
}
.certfile-text {
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
  }
}
.certfile-filename {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #333;
}
.certfile-detail {
  color: #a5acbc;
}
</style>
