import axios from 'axios';
// import { setupCache } from 'axios-cache-adapter'
// eslint-disable-next-line import/no-named-as-default
import AuthManager from './AuthManager';

/*
const cache = setupCache({
  maxAge: 15 * 60 * 1000
})
*/

export default class ApiClient {
  constructor(endpoint, API = 'root') {
    const urls = {
      root: process.env.VUE_APP_ROOT_API_ROOT,
      common: process.env.VUE_APP_ROOT_API_COMMON,
      stamp: process.env.VUE_APP_ROOT_API,
    };
    this.url = urls[API.toLowerCase()];
    this.endpoint = endpoint;
    this.authManager = AuthManager;
    this.axios = axios;
    // this.axios.defaults.adapter = cache.adapter;
  }

  async SetToken() {
    await this.authManager.IsLogged().then((user) => {
      if (user) {
        axios.defaults.headers.common.Authorization = `Bearer ${user.access_token}`;
        axios.defaults.headers.common['Content-Type'] = 'application/json';
      }
    });
  }

  async stream(query = null, method = 'POST', headers = null) {
    await this.SetToken();
    let internalHeaders = {
      Accept: 'application/octet-stream',
      responseType: 'blob',
    };
    if (headers) {
      internalHeaders = headers;
    }
    return axios({
      method: method,
      headers: internalHeaders,
      timeout: 60 * 10 * 1000,
      url: `${this.url}api/${this.endpoint}`,
      responseType: headers ? 'arraybuffer' : null,
      data: query,
    });
  }

  async upload(query = null, method = 'POST', onUploadProgress = () => {}) {
    await this.SetToken();
    return axios({
      method: method,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 60 * 10 * 1000,
      url: `${this.url}api/${this.endpoint}`,
      onUploadProgress: onUploadProgress,
      data: query,
    });
  }

  async Get(query = null) {
    await this.SetToken();
    return axios.get(`${this.url}api/${this.endpoint}/${query || ''}`).then(({ data }) => {
      return data;
    });
  }

  async Post(item) {
    await this.SetToken();
    return axios.post(`${this.url}api/${this.endpoint}`, item);
  }

  async Put(item) {
    await this.SetToken();
    return axios.put(`${this.url}api/${this.endpoint}`, item);
  }

  async Delete(id) {
    await this.SetToken();
    return axios.delete(`${this.url}api/${this.endpoint}/${id}`);
  }

  async DeleteMasive(query) {
    await this.SetToken();
    return axios.delete(`${this.url}api/${this.endpoint}`, {
      data: query,
    });
  }
}
