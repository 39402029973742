<template>
  <div>
    <Frame title="Certificados" :loading="loading">
      <Grid
        :data="data"
        :columns="columns"
        addText="Agregar CSD"
        :actions="actions"
        :showAdd="$can('AddCertificate', 'STM')"
        @addNew="addNew"
        @reloadDate="getCertificates"
      />
    </Frame>
    <Modal :active.sync="showModal" class="modal-with-overflow">
      <CertificateForm :certificateToEdit="certificateToEdit" :readonly="readonly" />
    </Modal>
  </div>
</template>

<script>
import ApiClient from '../../../utils/ApiClient';
import CertificateForm from './CertificateForm.vue';

export default {
  name: 'Certificates',
  components: {
    CertificateForm,
  },
  data() {
    return {
      showModal: false,
      loading: false,
      certificateToEdit: null,
      data: [],
      actions: [
        {
          text: 'Ver',
          callback: this.onView,
        },
      ],
      columns: [
        {
          title: 'RFC',
          field: 'rfc',
          searchable: true,
        },
        {
          title: 'Descripción',
          field: 'description',
          searchable: true,
        },
        {
          title: 'Día de emisión',
          field: 'issuingDate',
          format: 'd',
        },
        {
          title: 'Día de expiración',
          field: 'expirationDate',
          format: 'd',
        },
        {
          title: 'Número de serie',
          field: 'serialNumber',
          searchable: true,
        },
      ],
      readonly: false,
    };
  },
  mounted() {
    if (this.$can('EditCertificate', 'STM'))
      this.actions.push({
        text: 'Editar',
        callback: this.onEdit,
      });

    if (this.$can('DeleteCertificate', 'STM'))
      this.actions.push({
        text: 'Eliminar',
        callback: this.onDelete,
      });

    this.getCertificates();
    this.$root.$on('reloadData', () => {
      this.getCertificates();
    });
  },
  methods: {
    getCertificates() {
      this.loading = true;
      const apiClient = new ApiClient('CSD/Certificates', 'stamp');
      apiClient.Get('?query=a').then((data) => {
        this.loading = false;
        this.data = data;
      });
    },
    addNew() {
      this.readonly = false;
      this.certificateToEdit = null;
      this.showModal = true;
    },
    onView(item) {
      this.readonly = true;
      this.certificateToEdit = {
        description: item.description,
        RFC: item.rfc,
        llavePassword: '',
        certificateID: item.certificateID,
      };
      this.showModal = true;
    },
    onEdit(item) {
      this.readonly = false;
      this.certificateToEdit = {
        description: item.description,
        RFC: item.rfc,
        llavePassword: '',
        certificateID: item.certificateID,
      };
      this.showModal = true;
    },
    onDelete(item) {
      const self = this;
      this.deleteItem(
        'csd/certificates',
        item.certificateID,
        '¿Desea Eliminar el CSD?',
        'Al eliminar el CSD, no se podrá realizar timbrado o cancelación de nominas si no existe sellos activos',
        'stamp',
        self.getCertificates()
      );
    },
  },
};
</script>

<style lang="scss">
.modal-with-overflow {
  .pw_modal__modal-content {
    overflow-y: scroll;
  }
}
</style>
